<template>
  <ValidationProvider
    ref="select"
    v-slot="{ errors, required, ariaInput, ariaMsg, reset }"
    class="gl-select__wrapper"
    :class="{ 'gl-select__wrapper--full': fullWidth }"
    :name="name || label"
    :rules="rules"
    tag="div"
    :vid="vid"
  >
    <div
      v-if="label || maxLength"
      class="flex space-between mb-1"
    >
      <label
        class="gl-select__label"
        :class="{ 'gl-select__label--invalid': errors[0] }"
        :for="name"
      >
        {{ label || name }}
      </label>
      <div
        v-if="rules.includes('max') && maxLength"
        class="gl-select__label gl-modal__title--capitalize"
      >
        {{ value.length }} / {{ maxLength }} Symbols
      </div>
    </div>
    <v-select
      ref="vSelectRef"
      v-model="innerValue"
      :clearable="clearable"
      :disabled="disabled"
      :label="labelSelect"
      :loading="loading"
      :min="min"
      :multiple="multiple"
      :options="options"
      :placeholder="placeholder"
      :reduce="reduce || defaultReduce"
      :searchable="searchable"
      :style="`min-height: ${minHeight}${units}; margin-bottom: ${extraMarginBottom}; width: ${width};`"
      @open="open"
      @search="search"
    >
      <template #selected-option="selected">
        <slot
          :data="selected"
          name="selected-slot"
        />
      </template>
      <template #option="option">
        <slot
          :data="option"
          name="option-slot"
        />
      </template>
    </v-select>
    <span
      v-if="errors[0]"
      v-bind="ariaMsg"
      class="gl-select__error"
      :class="{ 'gl-select__error--block': isBlockErrorStyle }"
    >{{ errors[0] }}</span>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
export default {
  name: 'GlSelect',
  components: {
    ValidationProvider,
    vSelect,
  },
  inheritAttrs: false,
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    maxLength: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isBlockErrorStyle: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    units: {
      type: String,
      default: 'px',
    },
    extraMarginBottom: {
      type: String,
      default: '0',
    },
    min: {
      type: String,
      default: '0',
    },
    minHeight: {
      type: Number,
      default: 30,
    },
    width: {
      type: String,
      default: '100%',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, Array, String, Number],
      default: () => [],
    },
    hideControl: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    labelSelect: {
      type: String,
      default: '',
    },
    reduce: {
      type: Function,
      default: null,
    },
  },

  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('change', e)
        this.$emit('input', e)
      },
    },
  },
  methods: {
    clearValue() {
      this.innerValue = null
      this.$emit('clear')
    },
    open() {
      this.$emit('open')
    },
    search(e, fn) {
      this.$emit('search', e, fn)
    },
    defaultReduce(option) {
      return option
    },
  },
}
</script>
<style>
.gl-select__wrapper input {
  font-family: 'Montserrat', sans-serif;
}
.gl-select__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.gl-select__label {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--dark-grey-6-e);
  text-transform: uppercase;
}
.gl-select__label--invalid {
  color: var(--lipstick);
}
.gl-select__input--password-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.gl-select__error {
  color: var(--lipstick);
  font-size: 14px;
  margin-top: 12px;
}
.gl-select__error--block {
  display: block;
}
</style>
