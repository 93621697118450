<template>
  <gl-modal
    v-bind="$attrs"
    class="counterparty__choose-report-modal"
    closable
    info-only
    title="Create new report"
    width="320"
    v-on="$listeners"
    @close="$emit('close')"
  >
    <template #content>
      <div class="flex gap-4">
        <!-- <div
          class="counterparty__choose-report-modal_box"
          @click="chooseType('address-cluster')"
        >
          <div class="mb-12px">
            <gl-icon
              :height="38"
              name="wallet"
              style="min-width: 30px"
              :width="38"
            />
          </div>
          <div class="fs-14 bold-600 mb-2">
            By wallet address or cluster
          </div>
          <div class="fs-12 grey-text-6-e">
            You will be able to add wallet address or cluster one by one.
          </div>
        </div> -->
        <div
          class="counterparty__choose-report-modal_box"
          @click="chooseType('entity')"
        >
          <div class="mb-12px">
            <gl-icon
              :height="38"
              name="government-building"
              style="min-width: 30px"
              :width="38"
            />
          </div>
          <div class="fs-14 bold-600 mb-2">
            By entity
          </div>
          <div class="fs-12 grey-text-6-e">
            You will be able to select an entity from the list
          </div>
        </div>
      </div>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlIcon from '@/components/gl-icon'

export default {
  components: {
    GlModal,
    GlIcon,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    rules() {
      return `required`
    },
  },
  methods: {
    chooseType(type) {
      this.$emit('choose', type)
    },
  },
}
</script>

<style>
.counterparty__choose-report-modal .gl-modal__title {
  text-transform: unset;
  margin-bottom: 24px;
}
.counterparty__choose-report-modal .gl-modal__container {
  padding: 24px;
}

.counterparty__choose-report-modal_box {
  flex: 1;
  padding: 12px;
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 4px;
  cursor: pointer;
}

.counterparty__choose-report-modal_box:hover {
  box-shadow: 0 0 21px -7px rgba(90, 120, 234, 0.6);
  border-color: rgb(90, 120, 234, 0.6);
}
</style>
