function counterpartyReportFields() {
  return {
    title: '',
    address: { owner: [] },
    tx_hash: {},
    address_or_cluster: '',
    token: undefined,
    maxDepth: undefined,
    thresholdAmount: undefined,
    direction: 2,
  }
}
const directionsList = [
  {
    name: 'Source Of Funds',
    value: 0,
  },
  {
    name: 'Use Of Funds',
    value: 1,
  },
  {
    name: 'Both',
    value: 2,
  },
]

export { counterpartyReportFields, directionsList }
