<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    submit-title="Archive"
    title="Archive Report"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="mb-4">
      Are you sure you want to archive
      <strong class="break-word">{{ title }}</strong> report? The archive file
      can be retrieved.
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  methods: {
    onSubmit() {
      this.$emit('archive', { _id: this.id }, true)
      this.$emit('input', false)
    },
  },
}
</script>
