<template>
  <div class="counterparty-report">
    <div class="pa-3 flex align-end flex-wrap space-between gap-3">
      <div class="flex flex-1">
        <div class="m-fullwidth flex-1 pa-1 m-pa-0">
          <div class="tagging__key mb-2">
            Search by report name
          </div>
          <gl-search-box
            v-model="search"
            button-text="Search"
            dark-clear
            :disabled="!search"
            grey
            height="38px"
            :min-width="isMobile ? '200px' : '350px'"
            placeholder="Enter the report name"
            @clear="clearSearching"
            @search="loadData(activeTab)"
          />
        </div>
      </div>
      <div class="flex align-end flex-wrap gap-3 flex-1t">
        <!-- <div class="flex flex-1 pa-1 m-fullwidth m-pa-0">
          <div class="flex-1">
            <GlDatePicker
              v-model="transactionsListDates"
              class="counterparty-report__date-picker"
              :disabled-range="disabledRange"
              label="filter by date & time"
              @apply="setDate(transactionsListDates)"
              @cancel="handleDateClose"
              @clear="handleDateClose"
            />
          </div>
          <gl-menu-item
            class="m-flex align-end mb-1 ml-2 display-none"
            icon="delete-action"
            :icon-height="24"
            :icon-width="24"
            label="Clear all"
            @click="clearDate"
          />
        </div> -->
        <GlCoinSelect
          class="pa-1 m-pa-0"
          dark
          label="Filter by coin"
          @change="handleChangeCoin"
        />
        <div class="mb-2 m-mb-0">
          <gl-requests-counter />
        </div>

        <div
          v-if="selectedIds.length > 0 && isActiveReports"
          class="flex align-center mr-2 mb-1 pa-1 m-mb-0"
          @click="showRemoveSelectedModal = true"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="delete"
            :width="24"
          />
          <div class="warning-text pointer">
            Delete selected
          </div>
        </div>
        <div
          v-if="selectedIds.length > 0 && !isActiveReports"
          class="flex align-center mb-1 pa-1 mr-2 m-mb-0"
          @click="archiveReopenAll(false)"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="refresh"
            :width="24"
          />
          <div class="pointer">
            Reopen selected
          </div>
        </div>

        <div
          v-if="selectedIds.length > 0 && !isActiveReports"
          class="flex align-center mb-1 pa-2 pointer m-ml-0 m-mb-0"
          @click="openArchiveConfirmModal"
        >
          <gl-icon
            :height="24"
            name="folder"
            :width="24"
          />
          <div> Archive selected </div>
        </div>
        <!-- <div
          v-if="selectedIds.length === 0"
          class="flex align-center mb-1 pa-2 pointer m-mb-0"
          @click="selectAll"
        >
          <gl-icon
            :height="24"
            name="select-all"
            :width="24"
          />
          <div> Select all </div>
        </div>
        <div
          v-else
          class="flex align-center mb-1 pa-1 pointer m-mb-0"
          @click="deselectAll"
        >
          <gl-icon
            class="mr-1"
            :height="24"
            name="select-all"
            :width="24"
          />
          <div> Unselect all </div>
        </div> -->
      </div>
    </div>
    <div
      v-show="loading"
      class="flex align-center justify-center modal-loader"
      style="z-index: 10"
    >
      <gl-loader />
    </div>
    <div
      v-if="counterpartyList.length"
      class="counterparty-report__list"
    >
      <div
        v-for="(item, index) in counterpartyList"
        :key="`${item.title}${index}`"
        class="counterparty-report__item pointer"
        :class="{
          'counterparty-report__item-selected': isSelected(item._id),
        }"
        @click="openCounterpartyItem(item)"
      >
        <div class="counterparty-report__item-container">
          <div class="pa-3">
            <div
              v-if="item.inProgress"
              class="flex justify-center align-center fullwidth mb-4"
              style="height: 250px"
            >
              <div
                class="counterparty-report__item-img-progress spin-pulse-loader"
              >
                <gl-loader
                  height="100%"
                  spin-blue-view
                />
              </div>
            </div>
            <div v-else>
              <!-- <div
                v-if="index % 4"
                class="counterparty-report__item-img flex justify-center mb-4"
                :style="`backgroundImage: url('data:image/png;base64, ${item.image}')`"
              /> -->
              <div class="flex justify-center align-center mb-4 min-h-200">
                <img
                  alt="counterparty-preview"
                  class="fullwidth"
                  :src="getImagePreview(item.direction ?? item.directions)"
                >
              </div>
            </div>

            <div class="bold flex space-between mb-4">
              <div class="flex">
                <div
                  v-popover:tooltip.top="`${item.title}`"
                  class="ellipsis"
                  style="max-width: 200px; min-height: 28px; padding-top: 5px"
                >
                  {{ item.title }}
                </div>
                <!-- <gl-icon
                  v-if="!hasSelected"
                  class="ml-3"
                  :height="24"
                  name="edit-block-active"
                  :width="24"
                  @click.stop="openEditModal(item)"
                /> -->
              </div>
              <div
                v-click-outside="onClickOutside"
                class="relative"
              >
                <gl-checkbox
                  v-if="hasSelected"
                  v-model="item.selected"
                  class="pt-1"
                />
                <div v-if="!hasSelected">
                  <gl-icon
                    :height="24"
                    name="more"
                    :width="24"
                    @click.stop="openContextMenu(index, $event)"
                  />
                </div>
                <div
                  v-if="showContextMenu && index === contextMenuIndex"
                  class="counterparty-report__context-menu"
                >
                  <div v-if="isActiveReports">
                    <div
                      class="flex align-center mb-1"
                      @click.stop="openCounterpartyItem(item)"
                    >
                      <gl-icon
                        class="mr-2"
                        :height="24"
                        name="open-case"
                        :width="24"
                      />
                      <div> Open </div>
                    </div>
                    <div
                      class="flex align-center"
                      @click.stop="openRemoveModal(item)"
                    >
                      <gl-icon
                        class="mr-2"
                        :height="24"
                        name="delete"
                        :width="24"
                      />
                      <div class="warning-text">
                        Delete
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="flex align-center mb-1"
                      @click.stop="archiveReport(item, false)"
                    >
                      <gl-icon
                        class="mr-2"
                        :height="24"
                        name="refresh"
                        :width="24"
                      />
                      <div> Reopen </div>
                    </div>
                    <div
                      class="flex align-center"
                      @click.stop="openArchiveCounterpartyConfirmModal(item)"
                    >
                      <gl-icon
                        class="mr-2"
                        :height="24"
                        name="folder"
                        :width="24"
                      />
                      <div> Archive </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex space-between m-gap-3 m-flex-wrap">
              <InfoBlock
                v-if="item.blockchain"
                class="flex-1"
                label="Blockchain"
                :value="item.blockchain"
              />
              <InfoBlock
                class="flex-1"
                label="From"
                :value="
                  item.tx_hash &&
                    item.tx_hash.timestamp &&
                    item.tx_hash.timestamp.$gte
                    ? formatDate(
                      item.tx_hash.timestamp.$gte * 1000,
                      'dd.MM.yyyy',
                    )
                    : null
                "
              />
              <InfoBlock
                class="flex-1"
                label="To"
                :value="
                  item.tx_hash &&
                    item.tx_hash.timestamp &&
                    item.tx_hash.timestamp.$lte
                    ? formatDate(
                      item.tx_hash.timestamp.$lte * 1000,
                      'dd.MM.yyyy',
                    )
                    : null
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!counterpartyList.length && !loading"
      class="counterparty-report__list-empty"
    >
      No counterparty reports
    </div>
    <div
      v-if="counterpartyList.length && totalPages > 1"
      class="counterparty-report__footer flex space-between pa-1 m-mr-2"
    >
      <div class="flex align-center m-column pl-3 m-pt-2">
        <div class="mr-2 fs-14 m-fs-12 bold m-mb-3">
          Rows per page:
        </div>
        <vSelect
          v-model="perPage"
          class="stat-select__pagination mr-1"
          :clearable="false"
          :options="pagesOptions"
          :searchable="false"
          @input="countChange"
        />
      </div>
      <o-pagination
        :current.sync="currentPage"
        order="right"
        :per-page="perPage"
        simple
        :total="total"
        @change="pageChange"
      >
        <o-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page mr-2"
            :disabled="props.page.disabled"
            icon="left"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>

        <o-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page"
            :disabled="props.page.disabled"
            icon="right"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>
      </o-pagination>
    </div>
    <ArchiveSelectedModal
      v-model="showArchiveModal"
      :active-tab="activeTab"
      @archive="archiveReopenAll"
      @close="showArchiveModal = false"
    />
    <ArchiveReportModal
      :id="archivingCounterpartyItem._id"
      v-model="showArchiveReportModal"
      :title="archivingCounterpartyItem.title"
      @archive="archiveReport"
      @close="showArchiveReportModal = false"
    />
    <RemoveSelectedReportsModal
      v-model="showRemoveSelectedModal"
      @close="showRemoveSelectedModal = false"
      @remove="removeReport(selectedIds.join(','))"
    />
    <RemoveReportModal
      :id="removingCounterpartyItem.enqId"
      v-model="showRemoveModal"
      :title="removingCounterpartyItem.title"
      @close="showRemoveModal = false"
      @remove="removeReport"
    />
    <!-- <RenameReportModal
      v-model="showRenameModal"
      :title="updatingCounterpartyItem.title"
      @close="showRenameModal = false"
      @update="updateReport"
    /> -->
  </div>
</template>

<script>
//Component
import GlIcon from '@/components/gl-icon'
import GlMenuItem from '@/components/gl-menu-item'
import vSelect from 'vue-select'
import GlLoader from '@/components/gl-loader'
import GlSearchBox from '@/components/gl-search-box'
import RenameReportModal from '@/pages/counterparty/modals/RenameReportModal'
import ArchiveSelectedModal from '@/pages/counterparty/modals/ArchiveSelectedModal'
import ArchiveReportModal from '@/pages/counterparty/modals/ArchiveReportModal'
import RemoveSelectedReportsModal from '@/pages/counterparty/modals/RemoveSelectedReportsModal'
import RemoveReportModal from '@/pages/counterparty/modals/RemoveReportModal'
import GlCheckbox from '@/components/gl-checkbox'
import InfoBlock from '@/components/gl-info-block'
import GlDatePicker from '@/components/gl-date-picker'
import GlCoinSelect from '@/components/gl-coin-select.vue'
// Utils
import { formatDate, toSecondsStart, toSecondsEnd } from '@/utils/format-date'
import { scrollToSelector } from '@/utils/scroll-to'
import vClickOutside from 'v-click-outside'
// Vuex
import { mapActions, mapState } from 'vuex'
// libs
import moment from 'moment'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import GlRequestsCounter from '@/components/gl-requests-counter.vue'

export default {
  name: 'ReportList',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    GlRequestsCounter,
    GlCoinSelect,
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    GlCheckbox,
    GlSearchBox,
    RenameReportModal,
    ArchiveSelectedModal,
    ArchiveReportModal,
    RemoveSelectedReportsModal,
    RemoveReportModal,
    InfoBlock,
    GlDatePicker,
  },
  mixins: [deviceWidthMixin],
  props: {
    activeTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showContextMenu: false,
      showRenameModal: false,
      showArchiveModal: false,
      showArchiveReportModal: false,
      showRemoveModal: false,
      showRemoveSelectedModal: false,
      updatingCounterpartyItem: {},
      archivingCounterpartyItem: {},
      removingCounterpartyItem: {},
      selectedIds: [],
      loading: false,
      counterpartyList: [],
      pagesOptions: [6, 12, 24, 48, 96],
      currentPage: 1,
      perPage: 12,
      totalPages: 1,
      total: 1,
      search: '',
      transactionsListDates: [],
      showTimePanel: false,
      from: null,
      to: null,
      watchForListHandler: null,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList', 'coinData']),
    hasSelected() {
      return this.selectedIds.length > 0
    },
    isActiveReports() {
      return this.activeTab === 'active-reports'
    },
  },
  watch: {
    activeTab: {
      handler(tab) {
        (this.updatingCounterpartyItem = {}),
          (this.archivingCounterpartyItem = {}),
          (this.removingCounterpartyItem = {}),
          (this.selectedIds = [])
        this.showContextMenu = false
        this.currentPage = 1
        this.counterpartyList = []
        this.loadData(tab)
      },
    },
  },
  mounted() {
    const { from, to } = this.$route.query

    if (from && to) {
      this.from = from
      this.to = to
      this.transactionsListDates = [
        new Date(
          String(moment.unix(from / 1000).format('YYYY-MM-DD HH:mm:ss')),
        ),
        new Date(String(moment.unix(to / 1000).format('YYYY-MM-DD'))),
      ]
    }

    // this.gettingListInterval()
    this.loadData(this.activeTab)
  },
  beforeDestroy() {
    clearTimeout(this.watchForListHandler)
  },
  beforeRouteLeave(from, to, next) {
    clearTimeout(this.watchForListHandler)
    next()
  },
  methods: {
    ...mapActions('counterparty', [
      'deleteCounterpartyReport',
      'editCounterpartyReport',
      'counterpartyReportToArchive',
      'getCounterpartyReportById',
      'getCounterpartyList',
    ]),
    moment,
    formatDate,
    toSecondsStart,
    toSecondsEnd,
    scrollToSelector,
    async loadData(activeLocalTab = this.activeTab, isNeedLoading = true) {
      if (isNeedLoading) {
        this.loading = true
      }

      const searchingData = {
        from: this.from,
        to: this.to,
        title: this.search,
        count: this.perPage,
        skip: this.search ? 0 : (this.currentPage - 1) * this.perPage,
      }

      await this.getCounterpartyList({
        from:
          searchingData && searchingData.from ? searchingData.from : undefined,
        to: searchingData && searchingData.to ? searchingData.to : undefined,
        title:
          searchingData && searchingData.title
            ? searchingData.title
            : undefined,
        $limit: searchingData && searchingData.count ? searchingData.count : 6,
        $skip: searchingData && searchingData.skip ? searchingData.skip : 0,
        coin: this.coinData.key.toUpperCase(),
        // archived: activeLocalTab === 'archive',
      })
        .then(({ items, count }) => {
          if (this.activeTab === activeLocalTab) {
            this.total = count
            this.totalPages = Math.ceil(this.total / this.perPage)

            items = items.map(item => {
              const selected = this.selectedIds.includes(item._id)
              return {
                ...item,
                selected,
              }
            })

            this.counterpartyList = items
            this.loading = false
          }
        })
        .catch(({ response: { data } }) => {
          this.counterpartyList = []
          this.$toasted.global.error({ message: `${data.message}` })
        })
        .finally(() => {
          if (this.activeTab === activeLocalTab) {
            this.loading = false
          }
          this.$nextTick(() => {
            if (isNeedLoading) {
              this.selectedIds = []
            }
          })
        })

      if (this.$route.name === 'counterparty') {
        this.$router
          .replace({
            name: 'counterparty',
            query: { tab: this.activeTab, from: this.from, to: this.to },
          })
          .catch(err => err)
      }
    },
    gettingListInterval(interval = 6000) {
      if (this.$route.name === 'counterparty') {
        this.watchForListHandler = setTimeout(() => {
          this.loadData(this.activeTab, false).finally(() =>
            this.gettingListInterval(interval),
          )
        }, interval)
      }
    },
    handleChangeCoin() {
      // clearTimeout(this.watchForListHandler)
      // this.gettingListInterval()
      this.loadData(this.activeTab)
    },
    onClickOutside() {
      this.showContextMenu = false
    },
    openArchiveConfirmModal() {
      this.showArchiveModal = true
    },
    openArchiveCounterpartyConfirmModal(item) {
      this.archivingCounterpartyItem = item
      this.showArchiveReportModal = true
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.from = undefined
      this.to = undefined
      this.loadData(this.activeTab)
    },

    clearSearching() {
      this.search = ''
      this.loadData(this.activeTab)
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        this.from = this.toSecondsStart(from) * 1000
        this.to = this.toSecondsEnd(to) * 1000
        this.loadData(this.activeTab)
      } else this.clearDate()
    },
    clearDate() {
      this.transactionsListDates = []
      this.from = undefined
      this.to = undefined
      this.loadData(this.activeTab)
    },
    deselectAll() {
      this.selectedIds = []
      this.counterpartyList.forEach(item => (item.selected = false))
    },
    selectAll() {
      this.selectedIds = this.counterpartyList.map(item => item._id)

      this.counterpartyList.forEach(item => (item.selected = true))
    },
    removeReport(id) {
      this.deleteCounterpartyReport({ id }).then(() => {
        this.loadData(this.activeTab)
        this.showContextMenu = false
        this.selectedIds = []
      })
    },
    // updateReport(title) {
    //   this.editCounterpartyReport({
    //     id: this.updatingCounterpartyItem._id,
    //     title,
    //   }).then(({ success, data }) => {
    //     if (success) {
    //       this.getCounterpartyReportById(
    //         this.updatingCounterpartyItem._id,
    //       ).then(() => {
    //         this.loadData(this.activeTab)
    //         this.showRenameModal = false
    //       })
    //     } else {
    //       this.$toasted.global.error({ message: data.message })
    //     }
    //   })
    // },
    updateSelected({ _id }) {
      this.showContextMenu = false

      const listItem = this.counterpartyList.find(item => item._id === _id)

      if (this.isSelected(_id)) {
        const index = this.selectedIds.findIndex(id => id === _id)
        this.selectedIds.splice(index, 1)
        listItem.selected = false
      } else {
        this.selectedIds.push(_id)
        listItem.selected = true
      }
    },
    archiveReopenAll(status) {
      const ids = this.selectedIds.join(',')

      this.counterpartyReportToArchive({ ids, status }).then(({ success }) => {
        if (success) {
          this.loadData(this.activeTab)
          this.showContextMenu = false
          this.selectedIds = []
        }
      })
    },
    archiveReport({ _id }, status) {
      this.counterpartyReportToArchive({ ids: _id, status }).then(
        ({ success }) => {
          if (success) {
            this.loadData(this.activeTab)
            this.showContextMenu = false
            this.selectedIds = []
          }
        },
      )
    },
    isSelected(_id) {
      return this.selectedIds.find(id => String(id) === String(_id))
    },
    openEditModal(data) {
      this.showRenameModal = true
      this.updatingCounterpartyItem = data
    },
    openContextMenu(index) {
      this.showContextMenu = !this.showContextMenu
      this.contextMenuIndex = index
    },

    openRemoveModal(item) {
      this.removingCounterpartyItem = item
      this.showRemoveModal = true
    },

    countChange() {
      this.loadData(this.activeTab)
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData(this.activeTab)
      this.scrollToSelectorItem('.page-wrapper', '.counterparty', 300)
      this.scrollToSelectorItem(
        '.counterparty-report__list',
        '.counterparty-report__item',
        300,
      )
    },
    async openCounterpartyItem(item) {
      await this.$router.push({
        name: 'view-counterparty-report',
        params: { reportId: item.enqId },
        query: {
          availableDirection: item.direction ?? item.directions ?? 2,
          type: this.coinData.key,
        },
      })
    },
    disabledRange(date) {
      return date > moment().endOf('day')
    },
    scrollToSelectorItem(wrap, aim, offset) {
      this.scrollToSelector(wrap, aim, offset)
    },
    getImagePreview(direction) {
      let image = 'counterparty-preview.png'
      if (direction == 0) image = 'incoming-preview.png'
      if (direction == 1) image = 'outgoing-preview.png'
      return require(`@/assets/images/counterparty/${image}`)
    },
  },
}
</script>

<style>
.counterparty-report {
  display: flex;
  flex-direction: column;
  min-height: calc(100dvh - 125px);
  max-height: calc(100dvh - 125px);
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.select .vs__dropdown-toggle {
  height: 40px !important;
  background: #fff;
  border: none;
}

.counterparty-report__list {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 24px;
  overflow-y: auto;
  padding: 0px 20px;
  padding-bottom: 16px;
}

.counterparty-report__list a {
  text-decoration: none;
}

.counterparty-report__list-empty {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counterparty-report__item {
  box-sizing: border-box;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  background: #fff;
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  margin: 0;
  width: 100%;
  -webkit-transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    margin 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    margin 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.counterparty-report__item:hover {
  box-shadow: 0 0 21px -7px rgba(90, 120, 234, 0.6);
  border-color: rgb(90, 120, 234, 0.6);
}

.counterparty-report__item-container {
  width: 100%;
  position: relative;
}

.counterparty-report .mx-input {
  height: 38px !important;
}

.counterparty-report__context-menu {
  position: absolute;
  border-radius: 3px;
  box-shadow: 3px 3px 10px 0 rgba(89, 99, 135, 0.2);
  background-color: var(--white);
  padding: 16px;
  z-index: 25;
  transform: translate(-100%, 0);
  width: max-content;
}

.counterparty-report__date-picker {
  min-width: 350px;
}

.counterparty-report__item-selected {
  border-color: var(--reflex-bluet);
}

.counterparty-report__item-img {
  height: 250px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.counterparty-report__item-img-progress {
  height: 70px;
  width: 70px;
  background-size: 70px;
}

.counterparty-report .coin-select-base__dark .vs__dropdown-toggle {
  min-height: 38px;
}

.counterparty-report__footer {
  border-top: 1px solid var(--cotton-grey-f-5);
}
.counterparty-report__footer .o-pag {
  padding: 8px;
}

.counterparty-report__list
  .gl-checkbox__input:checked
  + .gl-checkbox__check:after {
  left: 3px;
  top: 3px;
}

@media (max-width: 1024px) {
  .counterparty-report__list {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}
@media (max-width: 767px) {
  .counterparty-report {
    min-height: unset;
    max-height: unset;
    height: auto;
  }

  .counterparty-report__list {
    grid-template-columns: fit-content(100%);
  }

  .counterparty-report__date-picker {
    min-width: 200px;
  }

  .counterparty-report .mx-input {
    height: 30px !important;
  }

  .counterparty-report .coin-select-base__dark .vs__dropdown-toggle {
    min-height: 30px;
  }
}

@media (max-width: 1024px) and (max-height: 650px) {
  .counterparty-report {
    min-height: unset;
    max-height: unset;
    height: auto;
  }
}
</style>
