<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    submit-title="Apply"
    title="Rename Report"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="mb-4">
      Enter the new report name in the field below
    </div>
    <gl-input
      v-model="title"
      class="gl-modal__row"
      label="New title"
      name="value"
      rules="required"
    />
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    onSubmit() {
      this.$emit('update', this.title)
    },
  },
}
</script>
