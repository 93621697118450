<template>
  <div class="counterparty__wrapper">
    <div v-if="$route.name && $route.name === 'view-counterparty-report'">
      <router-view @edit-report-modal-open="editReportModalOpen" />
    </div>

    <GlPageWrap
      v-else
      hide-title-on-mobile
      title="Counterparty report"
    >
      <template #actions>
        <GlButton
          class="min-w-250 m-fullwidth"
          pale-dark
          :style="[{ height: '38px' }]"
          title="Create new report"
          @click="chooseReportType('entity')"
        />
      </template>
      <div class="counterparty">
        <div class="tabs-wrapper flex m-fullwidth">
          <div
            class="tab tab-counterparty m-flex-1"
            :class="{ 'tab-active': activeTab === 'active-reports' }"
            @click="handleActiveTab('active-reports')"
          >
            <div class="mr-4 ml-4">
              Active Reports
            </div>
          </div>
          <!-- <div
            class="tab tab-counterparty m-flex-1"
            :class="{ 'tab-active': activeTab === 'archive' }"
            @click="handleActiveTab('archive')"
          >
            <div class="mr-4 ml-4">
              Archive
            </div>
          </div> -->
        </div>
        <!-- {{ formData }} -->
        <ReportList
          ref="reportListRef"
          :active-tab="activeTab"
        />
      </div>
    </GlPageWrap>
    <ChooseReportTypeModal
      v-model="showChooseReportTypeModal"
      @choose="chooseReportType"
      @close="chooseReportTypeModalClose"
    />
    <CreateEditReportModal
      v-model="showCreateEditReportModal"
      :loading="createEditReportModalLoading"
      :model-data="formData"
      :report-type="reportType"
      :report-type-condition="reportTypeCondition"
      :tags-list="tagsList"
      :types-list="typesList"
      @close="createEditReportModalClose"
      @confirm="createEditReportModalConfirm"
    />
  </div>
</template>

<script>
// Components
import ReportList from './components/ReportList'
import GlPageWrap from '@/components/layout/gl-page-wrap'
import GlButton from '@/components/gl-button'
import ChooseReportTypeModal from '@/pages/counterparty/modals/ChooseReportTypeModal'
import CreateEditReportModal from '@/pages/counterparty/modals/CreateEditReportModal'
// Utils
// import { isDefaultType } from '@/utils/find-default-tags-types'
// Vuex
import { mapActions } from 'vuex'
// Models
import { counterpartyReportFields } from '@/pages/counterparty//models/counterparty-report-fields'

export default {
  components: {
    GlPageWrap,
    GlButton,
    ReportList,
    ChooseReportTypeModal,
    CreateEditReportModal,
  },
  data() {
    return {
      search: '',
      activeTab: 'active-reports',
      reportType: '',
      reportTypeCondition: '',
      viewReportTitle: '',
      showChooseReportTypeModal: false,
      showCreateEditReportModal: false,
      createEditReportModalLoading: false,
      typesList: [],
      tagsList: [],
      formData: {},
    }
  },
  mounted() {
    const tab = this.$route.query.tab || this.activeTab

    if (tab) {
      this.activeTab = tab
      if (this.$route.name === 'counterparty') {
        this.$router
          .replace({ name: 'counterparty', query: { tab: this.activeTab } })
          .catch(err => err)
      }
    }
    this.setFormDataFields()
  },
  methods: {
    ...mapActions('tagging', ['getTags']),
    ...mapActions('types', ['getTypes']),
    ...mapActions('counterparty', ['createCounterpartyReport']),
    counterpartyReportFields,
    handleActiveTab(name) {
      this.activeTab = name
    },
    chooseReportType(type) {
      this.reportType = type
      this.showChooseReportTypeModal = false
      this.showCreateEditReportModal = true
      this.reportTypeCondition = 'create'
      this.setFormDataFields()
    },
    editReportModalOpen(data) {
      this.reportType = 'entity'
      this.showCreateEditReportModal = true
      this.reportTypeCondition = 'edit'
      this.formData = data
    },
    chooseReportTypeModalClose() {
      this.reportType = ''
      this.showChooseReportTypeModal = false
    },
    createEditReportModalClose() {
      this.$refs.reportListRef &&
        this.$refs.reportListRef.loadData(this.activeTab)
      this.showCreateEditReportModal = false
      this.reportTypeCondition = ''
    },
    createEditReportModalConfirm(data) {
      this.formData = data

      if (this.reportTypeCondition === 'create') {
        this.createReport(this.formData)
      }
      if (this.reportTypeCondition === 'edit') {
        // this.createReport(this.formData)
      }
    },
    setFormDataFields() {
      this.formData = this.counterpartyReportFields()
    },
    createReport(reportData) {
      this.createEditReportModalLoading = true

      this.createCounterpartyReport({
        ...reportData,
        directions: reportData.direction,
      })
        .then(() => {
          this.$toasted.global.success({
            message: `Report created successfully`,
          })
        })
        .catch(({ response: { data } }) => {
          data.message && this.$toasted.global.error({
            message: data.message,
          })
        })
        .finally(() => {
          this.createEditReportModalLoading = false
          this.createEditReportModalClose()
        })
    },
  },
}
</script>

<style>
.counterparty {
  display: flex;
  flex-direction: column;
  background: var(--white);
  min-height: var(--page-content-height);
  max-height: var(--page-content-height);
}

.counterparty .tab {
  width: 200px;
}

.tab-counterparty {
  font-size: 14px;
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-counterparty.tab-active::after {
  bottom: 0;
  background-color: var(--soft-blue);
}

.counterparty__wrapper::-webkit-scrollbar > * {
  width: 6px;
  height: 6px;
}

.counterparty__wrapper .gl-input__wrapper input,
.counterparty__wrapper .tagged .vs__selected,
.counterparty__wrapper .v-select .vs__dropdown-toggle,
.counterparty__wrapper .gl-date-picker input,
.counterparty__wrapper .gl-search-box__input-wrapper input {
  background: var(--cotton-grey-f-5) !important;
}

@media (max-width: 767px) {
  .counterparty {
    min-height: unset;
    max-height: initial;
  }
}

@media (max-width: 1024px) and (max-height: 650px) {
  .counterparty {
    min-height: unset;
    max-height: initial;
  }
}
</style>
