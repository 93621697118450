<template>
  <div>
    <div
      v-for="(item, i) in mainData"
      :key="i"
      class="flex column mb-4 fs-14"
    >
      <!-- {{ item }} -->
      <div
        class="flex align-center fullwidth mb-2"
        :class="{ 'align-end': i === 0 }"
      >
        <gl-input
          v-model.trim="item.address"
          class="fullwidth"
          :disabled="disabled"
          :height="38"
          is-block-error-style
          :label="i === 0 ? inputName : ''"
          :name="`${inputName} ${i + 1}`"
          placeholder="Add wallet address"
          :rules="targetRulesResult"
          @input="$emit('input', i, item)"
        >
          <template #afterInputSlot>
            <gl-icon
              v-if="i !== mainData.length - 1"
              class="mr-1 pointer"
              :height="24"
              link
              name="clear-large-dark"
              style="min-width: 24px"
              :width="24"
              @click="$emit('remove-target-input', i)"
            />
          </template>
        </gl-input>
      </div>

      <div
        v-if="item.loadCluster"
        class="flex align-center"
      >
        <gl-loader
          class="mr-1"
          height="24px"
          spin-gray-view
        />
        Checking cluster address
      </div>
      <div v-else>
        <div
          v-if="item.cluster"
          class="flex align-center"
        >
          <GlCheckbox
            v-if="!disabled"
            v-model="item.addCluster"
            class="fs-16 mb-3"
            :disabled="disabled"
          />
          <span class="fs-14">
            <span v-if="!disabled">Add</span> cluster {{ item.cluster }}
          </span>
        </div>
        <div v-else-if="item.address">
          No cluster data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlCheckbox from '@/components/gl-checkbox.vue'
import GlInput from '@/components/gl-input.vue'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'

export default {
  name: 'InputAutoAdd',
  components: {
    GlInput,
    GlIcon,
    GlCheckbox,
    GlLoader,
  },
  props: {
    mainData: {
      type: Array,
      default: () => [],
    },
    inputName: {
      type: String,
      default: '',
    },
    targetRules: {
      type: [String, Array, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    targetRulesResult() {
      if (this.mainData.length <= 1) {
        return this.targetRules + '|required'
      } else {
        return this.targetRules
      }
    },
  },
}
</script>
